import React from "react";
import { graphql } from "gatsby";

import Layout from "../layouts/default";
import SEO from "../utils/seo";
import PageTitle from "../components/PageTitle";

import * as styles from "./contact.module.css";

const RequiredField = <span className="text-secondary font-bold">*</span>;

const ContactPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="Contact"
        keywords={[`health`, `fitness`, `contact`, `email`, `support`]}
      />
      <PageTitle
        image={data.settings.headerImage.asset.fixed}
        title="Contact"
      />
      <section className="md:w-1/3 w-full mx-auto px-6 py-12">
        <form
          className={`flex flex-col transition-standard ${styles.contactForm}`}
          method="POST"
          action="https://formspree.io/lifelonghf@gmail.com"
        >
          <input type="text" name="_gotcha" style={{ display: "none" }} />
          <label className="pb-3">
            <p className="pb-2">Name {RequiredField}</p>
            <input
              className="transition-standard"
              type="text"
              name="name"
              id="name"
              required
            />
          </label>
          <label className="pb-3">
            <p className="pb-2">Email {RequiredField}</p>
            <input
              className="transition-standard"
              type="email"
              name="_replyto"
              id="email"
              required
            />
          </label>
          <label className="pb-3">
            <p className="pb-2">Subject</p>
            <input
              className="transition-standard"
              type="text"
              name="subject"
              id="subject"
            />
          </label>
          <label>
            <p className="pb-2">Message {RequiredField}</p>
            <textarea
              className="transition-standard"
              name="message"
              id="message"
              rows="8"
              required
            />
          </label>
          <p className="text-sm">
            Fields marked with
            <span className="mx-1">{RequiredField}</span>
            are required.
          </p>
          <button
            type="submit"
            id="contact-form-submit"
            className="transition-standard border border-link my-6 py-2"
          >
            Submit
          </button>
          <input
            type="reset"
            value="Clear"
            className="transition-standard cursor-pointer border border-link"
          />
        </form>
      </section>
    </Layout>
  );
};

export const query = graphql`
  query ContactPageQuery {
    settings: sanitySettings {
      headerImage: pageHeaderContact {
        asset {
          fixed(width: 1920, height: 300) {
            ...GatsbySanityImageFixed
          }
        }
      }
    }
  }
`;

export default ContactPage;
